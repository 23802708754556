@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body, h1, h2, h3, h4, h5 {
  font-family: 'Poppins', sans-serif !important;
}

body.page {
  padding-top: 60px;
  padding-bottom: 55px;
}

@media (min-width: 768px) {
  body.page {
    padding-bottom: 0;
  }
}

@media (min-width: 1024px) {
  body.page {
    padding-top: 65px;
  }
}

@media (min-width: 768px) {
  body.page-loja .main_content .section {
    padding-top: 40px;
  }
}

@media (min-width: 1024px) {
  body.page-loja .main_content .section {
    padding-top: 60px;
  }
}

.header_wrap.fixed-top {
  position: fixed !important;
  border-bottom: 1px solid #EEE !important;
}

.bottom__navigation--active {
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .bottom__navigation--active {
    padding-bottom: 0;
  }
}

.header_with_topbar.header_with_topbar {
  min-height: 65px;
}

.header_with_topbar.header_with_topbar .navbar__address {
  width: 100%;
}

@media (min-width: 1024px) {
  .header_with_topbar.header_with_topbar .navbar__address {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: auto;
  }
}

.header_with_topbar.header_with_topbar .navbar__address .nav-link {
  background-color: #F7F7F7;
  color: #687188;
  margin: 0 -1rem;
  padding: 2.5px 0;
  text-align: center;
}

@media (min-width: 575px) {
  .header_with_topbar.header_with_topbar .navbar__address .nav-link {
    background-color: transparent;
  }
}

@media (min-width: 1024px) {
  .header_with_topbar.header_with_topbar .navbar__address .nav-link {
    padding: 10px;
  }
  .header_with_topbar.header_with_topbar .navbar__address .nav-link strong {
    display: block;
  }
}

.header_with_topbar.header_with_topbar .navbar__address .nav-link strong {
  color: black;
}

.header__mobile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.header__mobile .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.header__mobile .container .header__left, .header__mobile .container .header__right {
  width: 40px;
}

.header__mobile .container .header__center .heading_s1 {
  margin: 0;
}

.header__mobile .container .btn {
  background-color: transparent !important;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 28px;
  height: 40px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0;
  width: 40px;
}

.header__mobile .container .btn i {
  margin: 0;
}

.bottom__navigation {
  -webkit-box-shadow: 0 0 -5px rgba(0, 0, 0, 0.15) !important;
          box-shadow: 0 0 -5px rgba(0, 0, 0, 0.15) !important;
  border-top: 1px solid #ddd;
  min-height: 60px;
}

@media (min-width: 992px) {
  .bottom__navigation {
    display: none;
  }
}

.bottom__navigation .navigation__items li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.bottom__navigation .navigation__items a {
  font-size: 22px;
  padding: 10px;
}

.search__store .product_search_form {
  bottom: 0;
  display: block;
  padding-left: 0;
  padding-right: 0;
  position: relative;
}

.product.no-shadow {
  -webkit-box-shadow: none;
          box-shadow: none;
}

/* .product.no-shadow .product_info {
  padding-left: 0;
  padding-right: 0;
} */

.product.no-shadow .btn.btn-block {
  font-size: 14px;
  padding-left: 0;
  padding-right: 0;
}

/* @media (min-width: 1024px) {
  .product.no-shadow .btn-add-product {
    opacity: 0;
  }
}

@media (min-width: 1024px) {
  .product.no-shadow:hover .btn-add-product {
    opacity: 1;
  }
} */

.quantity .minus, .quantity .plus {
  height: 30px;
  line-height: 30px;
  width: 30px;
}

.quantity .qty {
  width: 35px;
}

footer.bg_gray, footer.footer_dark {
  display: none;
}

@media (min-width: 768px) {
  footer.bg_gray, footer.footer_dark {
    display: block;
  }
}

footer.bg_gray .widget, footer.bg_gray .shopping_info .row, footer.bg_gray .bottom_footer, footer.footer_dark .widget, footer.footer_dark .shopping_info .row, footer.footer_dark .bottom_footer {
  opacity: 0;
}

.scrollup {
  bottom: 70px;
}

@media (min-width: 1024px) {
  .view_all {
    margin-top: -10px;
  }
}

#navCatContent {
  z-index: 9999999;
}

@media (min-width: 1024px) {
  #navCatContent {
    -webkit-box-shadow: none;
            box-shadow: none;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 99;
  }
}

@media (min-width: 1024px) {
  #navCatContent li a {
    font-size: 16px;
    padding-right: 0;
    padding-left: 0;
  }
}

.btn_cart_container {
  bottom: 60px;
  display: none;
  position: absolute;
  width: 100%;
}

.btn_cart_mobile {
  background-color: #06C167 !important;
  border-radius: 0;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 51px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
}

.btn_cart_mobile span {
  color: white;
  font-size: 16px;
}

.btn.disabled, .btn:disabled {
  pointer-events: none;
}

.btn_cart_mobile .btn_label {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.btn_cart_mobile .cart_count {
  background-color: rgba(0, 0, 0, 0.2);
  height: 20px;
  line-height: 20px;
  width: 20px;
}

.cart_box {
  background: white;
  -webkit-box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.1);
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  width: 100%;
}

@media (min-width: 768px) {
  .cart_box {
    max-width: 400px;
  }
}

.cart_box .cart_header {
  padding: 15px;
}

.cart_box .cart_header h5 span {
  color: #687188;
  display: block;
}

.cart_box .cart_header .close_cart_box {
  padding: 20px;
  position: absolute;
  top: 0;
  right: 0;
}

.cart_box .cart_list {
  max-height: calc(100% - 315px);
}

.cart_box .cart_list li {
  border-color: #DDD;
}

.cart_box .cart_footer .cart_total {
  padding: 5px 15px;
}

.cart_box .cart_footer .cart_total.cart_total_last {
  color: black;
}

.cart_box .cart_total, .cart_box .cart_quantity {
  color: #687188;
}

.cart_box .cart_box_inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
  padding: 15px;
}

.cart_box .empty_message {
  text-align: center;
}

.cart_box .empty_message i {
  display: block;
  font-size: 48px;
  margin-bottom: 1rem;
}

.dropdown-item.nav-link.nav_item.active span {
  color: black;
  font-weight: 800;
}

.order_review .product-qty {
  display: block;
}

@media (min-width: 1024px) {
  .order_review .product-qty {
    display: inline;
  }
}

.btn_cart_container.btn_cart_container_payment {
  bottom: 0;
  display: block;
}

.btn_cart_container.btn_cart_container_payment .btn {
  height: 60px;
}

.order_review_address {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}

@media (min-width: 1024px) {
  .order_review_resum {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
}
/*# sourceMappingURL=style-custom.css.map */

.basic-select-shipping {
  z-index: 2;
}
